import React, { useRef, useState, useEffect } from "react";
import { useForm, ValidationError } from "@formspree/react";
import {
  FormContainer,
  Fieldset,
  Legend,
  Input,
  SubmitButton,
  CenteredText,
  Subtext,
  Heading,
  SmallText,
  CheckboxLabel,
  CheckboxContainer
} from "./form-styles";
import { Toaster, ErrorToast } from "../toaster/toaster";
import { toast } from "react-toastify";
import { trackEvent } from "../../helpers";
import { Download } from "lucide-react";

export const ContactForm = () => {
  const [state, handleSubmit] = useForm("xzblnjbw");
  const [fullName, setFullName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [accessKeyword, setAccessKeyword] = useState<string>("");
  const [subscribeNewsletter, setSubscribeNewsletter] = useState<boolean>(false);

  const isMounted = useRef(false);

  const notify = () => {
    if (!isMounted.current) {
      toast.info("Your free resource is on the way", {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      isMounted.current = true;
    }
  };

  const errorNotify = () => {
    if (!isMounted.current) {
      toast.info("Error submitting form", {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      isMounted.current = true;
    }
  };

  const isDisabled =
    fullName.trim().length === 0 ||
    email.trim().length === 0 ||
    accessKeyword.trim().length === 0;

  const resetForm = () => {
    setFullName("");
    setEmail("");
    setAccessKeyword("");
    setSubscribeNewsletter(false);
  };

  useEffect(() => {
    if (state.succeeded) {
      notify();
      resetForm();
    }
  }, [state.succeeded]);

  useEffect(() => {
    if (state.errors) {
      errorNotify();
    }
  }, [state.errors]);

  return (
    <FormContainer onSubmit={handleSubmit}>
      <CenteredText>
        <Download size={48} color="#6b21a8" />
        <Heading>Get Instant Access</Heading>
        <Subtext>Fill out the form below to receive your free resource</Subtext>
      </CenteredText>
      <Fieldset>
        <Legend>Get your free resource emailed to you</Legend>
        <Input
          name="fullName"
          type="text"
          placeholder="Full Name"
          value={fullName}
          onChange={(e) => setFullName(e.target.value)}
          required
        />
        <ValidationError field="fullName" errors={state.errors} />
        
        <Input
          name="email"
          type="email"
          placeholder="Email address"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        <ValidationError field="email" errors={state.errors} />
        
        <Input
          name="access-keyword"
          type="text"
          placeholder="Access keyword"
          value={accessKeyword}
          onChange={(e) => setAccessKeyword(e.target.value)}
          required
        />
        <ValidationError field="access-keyword" errors={state.errors} />
        
        {/* Checkbox for subscribing to the newsletter */}
        <CheckboxContainer>
          <input
            type="checkbox"
            id="subscribe-newsletter"
            name="subscribe-newsletter"
            checked={subscribeNewsletter}
            onChange={(e) => setSubscribeNewsletter(e.target.checked)}
          />
          <CheckboxLabel htmlFor="subscribe-newsletter">
            Subscribe to the <b>ReneeLouise.tech</b> monthly newsletter 
          </CheckboxLabel>
        </CheckboxContainer>

        {state.succeeded && <Toaster />}
        {state.errors && <ErrorToast />}

        <SubmitButton
          isDisabled={isDisabled}
          disabled={state.submitting || isDisabled}
          onClick={() => trackEvent("Send message clicked")}
        >
          Email me my free resource
        </SubmitButton>
        
        <SmallText>Your information is secure and will never be shared with third parties.</SmallText>
      </Fieldset>
    </FormContainer>
  );
};
