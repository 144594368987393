import { Lock } from "lucide-react";
import {
  Arrow,
  Container,
  ContentWrapper,
  Description,
  ExclusiveContentContainer,
  LeftColumn,
  ListItem,
  Title,

} from "./lead-generation.styles";
import { ContactForm } from "../../components/form";


export const LeadGeneration = () =>
  <>
    <Container>
      <ContentWrapper>
        <LeftColumn>
          <ExclusiveContentContainer>
            <Lock size={16} /> <p>Exclusive Content</p>
          </ExclusiveContentContainer>
          <Title>Gain Exclusive Access to Game-Changing Opportunities</Title>
          <Description>
            Unlock free, high-value resources designed to accelerate your growth in tech, business, and productivity. From insider opportunities to expert insights, get the tools you need to stay ahead.
          </Description>
          <ListItem>
            <Arrow />  Exclusive career and business opportunities
          </ListItem>
          <ListItem>
            <Arrow />Proven productivity frameworks and strategies
          </ListItem>
          <ListItem>
            <Arrow /> Expert insights to help you level up
          </ListItem>
        </LeftColumn>
        <ContactForm />
      </ContentWrapper>
    </Container>

  </>





