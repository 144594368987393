import {
  slantedLineSeparatorDark,
  logoLight,
  slantedLineSeparatorWhite,
  instagram2,
  linkedin2,
} from "../../assets";
import {
  Separator,
  Card,
  ContentContainer,
  FooterText,
  IconWrapper,
  LinkCard,
  LinkDescription,
  LinkTitle,
  Subtitle,
  TextWrapper,
  Title,
  CardContainer,
  Icon
} from "./contact.styles";
import { Footer } from "../../components/footer";
import { Background } from "../../components/background";
import { Mail} from "lucide-react";


export const Contact = () => 
    <>
      <Background />
      <Separator src={slantedLineSeparatorWhite} alt="" />

      <ContentContainer>
      <Card>
        <div style={{ textAlign: "center" }}>
          <Title>Let's Connect</Title>
          <Subtitle>Feel free to reach out through any of these channels</Subtitle>
        </div>

        <CardContainer>
          <LinkCard href="mailto:hello@reneelouise.tech">
            <IconWrapper>
              <Mail size={24} color="#2563eb" />
            </IconWrapper>
            <TextWrapper>
              <LinkTitle>Email</LinkTitle>
              <LinkDescription>Drop me a line anytime</LinkDescription>
            </TextWrapper>
          </LinkCard>

          <LinkCard
            href="https://instagram.com/reneelouise.tech"
            target="_blank"
            rel="noopener noreferrer"
          >
            <IconWrapper>
              <Icon src={instagram2} alt="instagram logo"/>
            </IconWrapper>
            <TextWrapper>
              <LinkTitle>Instagram</LinkTitle>
              <LinkDescription>Send me a DM</LinkDescription>
            </TextWrapper>
          </LinkCard>

          <LinkCard
            href="https://www.linkedin.com/in/reneelouise/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <IconWrapper>
            <Icon src={linkedin2} alt="instagram logo"/>
            </IconWrapper>
            <TextWrapper>
              <LinkTitle>LinkedIn</LinkTitle>
              <LinkDescription>Let's connect professionally</LinkDescription>
            </TextWrapper>
          </LinkCard>
        </CardContainer>

        <FooterText>I'll get back to you as soon as possible!</FooterText>
      </Card>
    </ContentContainer>
     
      <Footer
        separator={slantedLineSeparatorDark}
        color="#fff"
        logo={logoLight}
      />
    </>
 

