import styled, {css} from "styled-components";

interface StyleProps {
  isDisabled: boolean;
}

export const FormContainer = styled.form`
  background: white;
  border-radius: 1rem;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  padding: 2rem;
`;


export const Text = styled.p`
font-size:12px;
margin-bottom:48px;
`;

export const SmallText = styled.p`
  text-align: center;
  font-size: 0.875rem;
  color: #718096;
`;

export const FormSuccessContainer = styled.form`
  padding: 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #ededec;
  border: none;
  width: 50%;

  @media screen and (max-width: 825px) {
    width: 100%;
  }
`;

export const CheckboxContainer = styled.div`
  display: flex;
  align-items: flex-start;
  margin-bottom: 10px;
  cursor: pointer;
`;

export const CheckboxLabel = styled.label`
  font-size: 14px;
  color: #333;
  cursor: pointer;
  user-select: none;
  margin-left: 4px;
`;

export const CenteredText = styled.div`
  text-align: center;
`;

export const Heading = styled.h2`
  margin-top: 1rem;
  font-size: 1.5rem;
  font-weight: bold;
  color: #1a202c;
`;

export const Subtext = styled.p`
  color: #4a5568;
  margin-top: 0.5rem;
`;

export const FormSubheading = styled.p``;

export const Fieldset = styled.fieldset`
  all: unset;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const Legend = styled.legend`
  visibility: hidden;
`;
export const Input = styled.input`
  height: 32px;
  margin-bottom: 8px;
  border: 0.5px solid grey;
  border-radius: 4px;
  padding: 4px;
  padding-left:12px;
  font-size: 16px;
`;

export const SubmitButton = styled.button<StyleProps>(
  ({ isDisabled }: StyleProps) => css`
    width: 100%;
    height: fit-content;
    padding: 0.75rem;
    font-weight: 400px;
    font-family: inherit;
    border-radius: 4px;
    border: none;
    color: #fff;
    bottom: 5px;
    right: 22px;
    font-size: 16px;
    font-weight: 200px;
    opacity: ${isDisabled ? '80%' : '100%'};
    background-color: rgb(107, 33, 168);
    cursor: ${isDisabled ? 'not-allowed' : 'pointer'};

    &:hover {
      opacity: ${isDisabled ? '80%' : '100%'};
      outline: ${isDisabled ? 'none' : '1px solid #150d6b'};
      transition: all 0.2s ease-in-out 0s;
    }

    @media screen and (max-width: 825px) {
      right: 26px;
    }
  `
);
  


export const Toaster = styled.div``;

