import { ArrowRight } from "lucide-react";
import styled from "styled-components";

export const Container = styled.div`
  min-height: 100vh;
  background: linear-gradient(to bottom right, #faf5ff, #ebf4ff, #c3dafe);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4rem 1rem;
`;

export const ContentWrapper = styled.div`
  max-width: 72rem;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  gap: 4rem;
  align-items: center;
  
  @media (min-width: 1024px) {
    grid-template-columns: repeat(2, 1fr);
  }
`;

export const LeftColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
`;

export const Title = styled.h1`
  font-size: 2.5rem;
  font-weight: bold;
  color: #1a202c;
  line-height: 1.2;
`;

export const Description = styled.p`
  font-size: 1.25rem;
  color: #4a5568;
`;

export const ListItem = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  color: #4a5568;
`;

export const ExclusiveContentContainer = styled.div`
  display: inline-flex;
  align-items: center;
  padding: 0.25rem 1rem;
  background: #ede9fe;
  color: #6b21a8;
  border-radius: 9999px; /* Ensures a fully rounded pill shape */
  font-size: 0.875rem;
  font-weight: 500;
  width: fit-content;
  gap: 8px;
  font-weight: 600;
 `

export const Arrow = styled(ArrowRight)`
width: 16px;
height: 16px;
color: #16a34a;
background-color:rgb(176, 240, 161); 
border-radius: 50%; 
padding:4px;
`
  ;