import posthog from "posthog-js";
import {
  twitter,
  twitterWhite,
  linkedin,
  linkedinWhite,
  codeScanner,
  delivery,
  askvia,
  glowingInTech,
  accessibility,
  jtlTech,
  codingPhoto,
  teamProductivity,
  softwareDevelopment,
  productEngineering,
  handshake,
  innovation,
  honesty,
  collaboration,
  instagramWhite,
  instagram,
  github,
  githubDark,
  codingBlackFemaless,
  nanodegree,
  unWomenUk,
  blackCodher,
  website,
  websiteWhite,
  renee2,
  thumbnail5,
  thumbnail1,
  thumbnail6,
  thumbnail2,
  thumbnail3,
  thumbnail4,
  thumbnail7,
  thumbnail8,
  thumbnail9,
} from "./assets";
import { ProjectBulletProps } from "./components/tech-project-bullet/tech-project.types";

interface Video {
  id: string;
  title: string;
  description: string;
  publishedAt: string;
  thumbnailUrl: string;
  duration: string;
}

interface IStory {
  type: string;
  src?: string;
  title: string;
  shortDescription: string;
  alt?: string;
}

interface ICertificate {
  type: string;
  skills: string[];
  src?: string;
  alt?: string;
}

export interface IService {
  offering: string;
  shortDescription: string;
  src?: string;
  alt?: string;
  url?: string;
}

export interface IResource {
  resource: string;
  shortDescription: string;
  logo: string;
}

interface IColor {
  light: string;
  dark: string;
}

interface ISocial {
  src: IColor;
  alt: string;
  url: string;
}

interface IValue {
  value: string;
  src: string;
  desc: string;
}

export const values: IValue[] = [
  {
    value: "Client-Centricity",
    src: handshake,
    desc: "Our clients are at the heart of everything we do. We prioritize their success by delivering top-tier solutions and outstanding service",
  },
  {
    value: "Continuous Innovation",
    src: innovation,
    desc: "We foster a culture of innovation, staying at the forefront of technology to provide our clients with cutting-edge solutions",
  },
  {
    value: "Integrity and Ethics",
    src: honesty,
    desc: "We uphold the highest standards of integrity and ethics in all our interactions, ensuring transparency, trust, and honesty",
  },
  {
    value: "Team Collaboration",
    src: collaboration,
    desc: "We believe in the power of collaboration and teamwork. We leverage our collective expertise to tackle complex challenges and achieve remarkable results",
  },
];

export const initialStories: IStory[] = [
  {
    type: "App website",
    src: jtlTech,
    title: "Jump The Line",
    shortDescription:
      "Start up drives adoption of its iOS app with professional and sleek website",
    alt: "Jump the line homepage",
  },
  {
    type: "Software development",
    src: codingPhoto,
    title: "Confidential company",
    shortDescription:
      "Award winning enterprise transforms monolith into microservices using modern technologies like React",
    alt: "Jump the line homepage",
  },
  {
    type: "App website",
    src: askvia,
    title: "Askvia",
    shortDescription:
      "Askvia drives adoption of its AI Whatsapp app with professional, secure and sleek website",
    alt: "Robot hand with futuristic design",
  },
  {
    type: "System upgrade",
    src: delivery,
    title: "Confidential company",
    shortDescription:
      "UK-Based Retail Giant Upgrades to a High-Performance Delivery Tracking System",
    alt: "Man passing bag to delivery driver",
  },
  {
    type: "System upgrade",
    src: teamProductivity,
    title: "London Luton Airport",
    shortDescription:
      "Top company improves team productivity by creating new headless CMS using modern technologies",
    alt: "Group of people working around a table with devices",
  },
  {
    type: "Website extension",
    src: codeScanner,
    title: "Black Valley",
    shortDescription: "Website extended to accommodate for new feature",
    alt: "Laptop screen with code in darkness",
  },
];

export const extraStories: IStory[] = [
  {
    type: "Women Of Silicon Roundabout '24",
    src: renee2,
    title: "Blaq Byte",
    shortDescription:
      "Beyond The CheckBox: Building Inclusive tech spaces and pathways for diverse talent",
    alt: "Building Inclusive tech spaces and pathways for diverse talent",
  },
  {
    type: "Glowing In Tech",
    src: glowingInTech,
    title: "Black Valley",
    shortDescription:
      "Building tech communities to empower underrepresented groups",
    alt: "Keyboard with accessibility buttons",
  },
  {
    type: "Coding Black Females",
    src: codingBlackFemaless,
    title: "Codescanner.AI",
    shortDescription:
      "My journey from Teaching to Technology with Coding Black Females and Beamery",
    alt: "Open AI logo on screen",
  },
];

export const certificates: ICertificate[] = [
  {
    type: "Code First Girls Nanodegree",
    src: nanodegree,
    alt: "code first girls nanodegree certificate",
    skills: [],
  },
  {
    type: "UN Women UK Delegate",
    src: unWomenUk,
    alt: "UN women UK delegate certificate",
    skills: [],
  },
  {
    type: "Black CodHer",
    src: blackCodher,
    alt: "black code her certificate",
    skills: [],
  },
];

export const socialLinks: ISocial[] = [
  {
    src: { light: twitterWhite, dark: twitter },
    alt: "twitter icon",
    url: "https://twitter.com/Reneelouisetech",
  },
  {
    src: { light: linkedinWhite, dark: linkedin },
    alt: "linkedin icon",
    url: "https://www.linkedin.com/in/reneelouise/",
  },
  {
    src: { light: instagramWhite, dark: instagram },
    alt: "instagram icon",
    url: "https://www.instagram.com/reneelouise.tech",
  },
  {
    src: { light: github, dark: github },
    alt: "github icon",
    url: "https://www.github.com/reneelouise",
  },
];

export const blaqByteSocialLinks: ISocial[] = [
  {
    src: { light: twitterWhite, dark: twitter },
    alt: "twitter icon",
    url: "https://www.twitter.com/blaqbyte",
  },
  {
    src: { light: instagramWhite, dark: instagram },
    alt: "instagram icon",
    url: "https://www.instagram.com/blaqbyte",
  },
  {
    src: { light: linkedinWhite, dark: instagram },
    alt: "instagram icon",
    url: "https://www.instagram.com/blaqbyte",
  },
  {
    src: { light: websiteWhite, dark: instagram },
    alt: "instagram icon",
    url: "https://www.blaqbyte.com",
  },
];

export const softwareServices: IService[] = [
  {
    src: softwareDevelopment,
    alt: "Two women programming",
    url: "https://www.blaqbyte.com",
    offering: "1:1 Engineering Mentorship",
    shortDescription:
      "Considering a career in Engineering? Sign up to Blaq Byte mailing list and Book a FREE Engineering mentorship slot to get the guidance you need.",
  },
  {
    src: productEngineering,
    alt: "Get a Software Quote",
    url: "https://rolkconsulting.com/software-services",
    offering: "Consulting Services",
    shortDescription:
      "Ready to elevate your digital presence? Explore our consulting services for expert guidance on enhancing your business or personal brand.",
  },
  {
    src: accessibility,
    alt: "Desktop screen with text promoting accessibility",
    url: "https://www.reneelouise.tech/contact",
    offering: "Business Enquiries",
    shortDescription:
      "Interested in speaking engagements, brand partnerships, or other opportunities? Share your details, and we'll get in touch shortly.",
  },
];

export const testimonials = [
  {
    id: 1,
    text: "I have had the privilege of working with Renee as her Engineering Manager at Beamery, and it's clear that she is an invaluable asset to any team. She's motivated and reliable, seeing through her work with dedication and excellence. This includes the technical side of engineering, especially writing Typescript and React code, where she delivered whole new components and upgraded our accessibility. She also brings a lot of broader skills like refinement, project management, and team ceremonies, with valuable contributions in meetings, clear articulation of problems and solutions, and she always does it with compassion and candour.",
    author: "Dan B, Engineering Manager",
  },
  {
    id: 2,
    text: "Working with Renee has been a great experience. She's really passionate about work and is continuously looking to improve her technical skills. I’ve had a chance to work with Renee on several projects and I can note that she is very detail oriented and highly responsible in delivering projects. Besides having great work ethics, Renee has shown skills of a great leader too, taking charge and setting up a hackathon for our team, which not only showed off her technical and organisational skills, but also brought the whole team together in collaboration. Moreover, her proactive approach in independently handling tasks highlights her dedication and resourcefulness. I endorse Renee for her outstanding commitment and valuable contributions.",
    author: "Carolina P, Senior Frontend Engineer",
  },
  {
    id: 3,
    text: "Renee is a great team member who has consistently delivered high-quality work on the projects we've worked on together. She actively pursues opportunities to enhance her technical skills for her professional growth. Her willingness to seek clarification through thoughtful questions shows her commitment to understanding tasks thoroughly which contributes to a collaborative team environment. Renee's impressive leadership skills were showcased when she organized a successful team hackathon, demonstrating her ability to coordinate the team effectively. She also plays a key role in fostering a documentation culture within the team, ensuring technical processes and team procedures are well-documentation for knowledge sharing.",
    author: "Oluwadamilola A, Backend Engineer",
  },

  {
    id: 4,
    text: "I recommend Renée for her exceptional ability to inspire audiences in the technology sector. With a non-traditional journey into tech herself, Renée is not just compelling but highly motivational. She excels in making complex concepts accessible and relatable, empowering listeners from all backgrounds. Renée’s talks are a unique blend of personal narrative and technical insight, inspiring anyone to pursue their tech ambitions regardless of their background.",
    author: "Max B, Talent, People & Operations",
  },
  {
    id: 5,
    text: "Renee is not only a talented Software Engineer but has consistently demonstrated senior-level proficiency throughout our collaboration. Renee stands out for her forward-thinking approach to software engineering. She goes beyond the immediate coding tasks, always considering the broader vision of the user experience. This foresight allows her to set up projects for success, ensuring that the development aligns seamlessly with the overarching goals. One of Renee's standout qualities is her proactive nature when it comes to documentation and technical requirements. She not only excels in coding but also takes the initiative to meticulously document processes and requirements. This proactive documentation approach has been instrumental in the success of our projects, providing clarity and guidance for the entire team. Working with Renee has been an amazing experience. Her ability to act at a senior level, coupled with her dedication to understanding the future vision of user experience, sets her apart as a software engineer. I am confident that Renee's exceptional skills and commitment to excellence will continue to make a significant impact on any project she undertakes.",
    author: "Daniil V, Senior Product Designer",
  },
  {
    id: 6,
    text: "I have had the pleasure of working closely with Renée for the past year as part of our dynamic core development team. During this time, I have been consistently impressed by her dedication, quick learning abilities, and proactive approach to professional development. Renée is an exceptionally quick learner, consistently demonstrating a strong eagerness to enhance her skills. What sets her apart is her strategic approach to skill development, showcasing excellent planning skills when it comes to diving deeper into frontend technologies and seamlessly applying them to her day-to-day responsibilities. The other standout qualities is her proactive nature in seeking feedback. She not only actively seeks input but also takes meaningful actions to implement suggested changes. This proactive approach has led to noticeable performance improvements. It's clear that she values continuous improvement and is committed to delivering high-quality work. Recently Renée has demonstrated her ability to take on larger responsibilities. Her leadership skills shine through in her role, where she has successfully led hackathon and fostered good knowledge sharing, documentation culture.",
    author: "Meng Li W, Engineering Manager",
  },
  {
    id: 7,
    text: "Since the early stages of her career, Renée has consistently demonstrated key attributes that mark her as a future 'rockstar' engineer: insatiable curiosity, an incredibly disciplined approach to learning, a willingness to step outside her comfort zone, and (most importantly) being a genuinely lovely person to work with. I am confident that Renée's unique blend of skills and qualities will make her a valuable asset to any team or project",
    author: "Stephanie S, Senior Software Engineer",
  },
  {
    id: 8,
    text: "The main takeaway from the talk Beyond The Checkbox is how we can do more as business to ensure we are aligning ourselves and partnering with bootcamps that are truly diverse. We partner with lots of bootcamps that focus on women but the talk you delivered made me think wider and broader.",
    author: "Eva P, UK Director",
  },
  {
    id: 9,
    text: "I am so grateful to have had Renee's mentorship over the years. She helped me land my dream tech role at an Investment Bank and has checked in regularly to help me navigate the peaks and troughs of my career. As a career-switcher, her support has been invaluable.",
    author: "Olivia O, Full stack Software Engineer",
  },
];

export const phrases = [
  "Software Engineer II",
  "Full Stack Instructor",
  "Keynote Speaker",
  "Technology Consultant",
  "Engineering Mentor",
  "Blaq Byte Podcast Host",
  "Founder of DevLoop.sh",
];

export const CVphrases = [
  "Software Engineer II",
  "Full Stack Instructor",
  "Keynote Speaker",
  "Technology Consultant",
  "Engineering Mentor",
  "Blaq Byte Podcast Host",
  "Founder of DevLoop.sh",
  "Technical Lead",
  "Accessibility SME",
];

export const mobilePhrases = [
  "Engineer",
  "Instructor",
  "Speaker",
  "Consultant",
  "Mentor",
  "Founder",
  "Leader",
];

// technical projects for my CV page
export const technicalProjects: ProjectBulletProps[] = [
  {
    startText:
      "Contributed significantly to migrating 3 products from a legacy monolithic platform to independently owned back-end microservices and microfrontend apps, ",
    endText:
      "leading to a 25% increase in deployment frequency for these products and a 15% decrease in the change failure rate. This transformation marked a substantial improvement in agility and reliability for the entire product suite",
    skills: [
      "React",
      "Typescript",
      "Microservices",
      "Microfrontend",
      "Accessibility",
      "CSS",
      "Launch Darkly",
      "Terraform",
      "Github",
      "Gitlab",
      "Legacy code",
      "Product management",
      "Project architecture",
    ],
  },
  {
    startText:
      "Revamped and optimised the vacancies list microfrontend using React and Typescript, ",
    endText:
      "resulting in a modern and sleek design that garnered an impressive 94% adoption rate within the first month of launch. Personally responsible for ensuring accessibility, the project successfully passed WCAG compliance during scheduled external auditing, showcasing a commitment to inclusive design principles.",
    skills: [
      "React",
      "Typescript",
      "API",
      "Microfrontend",
      "Accessibility",
      "CSS",
      "Launch Darkly",
      "Terraform",
      "Github",
      "Gitlab",
      "Communication",
      "Stakeholder management",
    ],
  },
  {
    startText:
      "Improved and oversaw the enhancement of the Christmas Lunch On Jesus (CLOJ) WordPress website, simultaneously handling all IT requirements for CLOJ.",
    endText:
      "Elevated platform functionality by implementing QR code registration and hub features. Established a fail-safe Google Forms system for contingency in website crashes and order management. Resolved website bugs, safeguarding against invoice loss. Additionally, upgraded dependencies for enhanced performance.",
    skills: ["Wordpress", "PHP", "HTML", "Project management", "Leadership"],
  },
  // {
  //   startText:
  //     "Revamped and project-managed the Affordability Calculator feature for Mappa, leveraging React, LeafletJS and React Leaflet to enhance both user and developer experiences through the implementation of best practices. ",
  //   endText:
  //     "The successful rebuild yielded a notable 18% increase in Weekly Active Users (WAU) within the first month of the feature's re-launch, demonstrating the positive impact on engagement and usability. By implementing the best practices and documenting processes thoroughly, this allowed the developer team to be able to easily understand the code and iterate on the feature.",
  //   skills: [
  //     "React",
  //     "React Leaflet",
  //     "Product Management",
  //     "Figma",
  //     "Github",
  //     "HotJar",
  //     "Product Hog",
  //     "CSS",
  //   ],
  // },
  {
    startText:
      "Contributed significantly to the 99.98% system uptime by implementing strategic improvements in resolving P2 and P3 bugs, resulting in a remarkable 75% reduction in resolution time. ",
    endText:
      "This optimisation played a pivotal role in sustaining the reliability and continuous operation of critical components, including Beamery's search stack, authentication, and bulk processing.",
    skills: ["Scalability", "Observability", "Uptime optimisation"],
  },
  {
    startText:
      "Championed internationalisation (i18n) initiatives at Beamery, introducing browser language-detectable translations using React-i18next and Lokalise. ",
    endText:
      "This tactical implementation not only elevated user experience by delivering content in their preferred language but also substantially improved product accessibility. The result was an expanded reach into previously untapped markets, leading to Beamery securing a remarkable 20% increase in potential market share. This success underscores the effective alignment of technology with evolving market demands.",
    skills: [
      "Internationalisation (i18n)",
      "React-i18next",
      "Lokalise",
      "Technical implementation",
      "User experience optimisation",
      "Product accessibility",
      "Problem-solving",
      "Innovative solutions",
      "Project management",
      "Adaptability",
      "Effective communication",
      "Collaboration",
      "Leadership",
      "Data-driven decision-making",
    ],
  },
  {
    startText:
      "Led a cross-functional team of six through a high-stakes hackathon, orchestrating strategic planning, daily standups, and collaborative efforts to deliver a compelling proof of concept within an ambitious five-day timeframe.  ",
    endText:
      "The successful project is actively utilised in pitches, contributing to business wins and providing valuable customer insights.",
    skills: [
      "Leadership",
      "Cross-functional collaboration",
      "Strategic planning",
      "Project coordination",
      "Effective communication",
      "Agile methodology",
      "Problem-solving",
      "Time management",
      "Adaptability",
      "Documentation",
      "Team building",
      "Presentation skills",
      "Feedback facilitation",
      "Demonstrated results",
      "Innovative thinking",
      "React",
      "CSS",
      "TypeScript",
      "Technical project management",
    ],
  },
  {
    startText:
      "Demonstrated adept leadership in a dynamic setting, fostering open communication, encouraging feedback, and ensuring documentation of outcomes. ",
    endText:
      "Resulted in a cohesive team dynamic, successful project delivery, and the tangible impact of a widely adopted proof of concept in the solutions consultant workflow.",
    skills: [
      "Leadership",
      "Dynamic leadership",
      "Feedback facilitation",
      "Documentation",
      "Team coordination",
      "Project management",
      "Strategic impact",
      "Adaptability",
      "Collaboration",
      "Effective communication",
      "Innovative thinking",
      "Workflow optimisation",
    ],
  },
];

export const experience: ProjectBulletProps[] = [
  {
    startText: "Oct 2021 - present:",
    endText: "Beamery, Software Engineer II",
  },
  {
    startText: "Feb 2024 - Mar 2024:",
    endText: "United Nations, UN Women UK Delegate",
  },
  {
    startText: "Feb 2023 - Mar 2023:",
    endText: "United Nations, UN Women UK Delegate",
  },
  {
    startText: "Aug 2022 - Nov 2022:",
    endText: "Code First Girls, Full Stack Nanodegree Instructor",
  },
  {
    startText: "June 2022 - Nov 2022:",
    endText: "Jesus House For All Nations, Tech Lead",
  },
  {
    startText: "Aug 2021 - Feb 2022:",
    endText: "BlackCodHer, Full Stack Software Developer",
  },
  {
    startText: "Dec 2020 - Aug 2021:",
    endText: "JTL Technologies, Full Stack Software Developer ",
  },
  {
    startText: "Sep 2019 - Aug 2020:",
    endText: "Secondary Mathematics Teacher at Christ's College Finchley",
  },
  {
    startText: "Jan 2018 - Aug 2021:",
    endText: "Freelance Full Stack Developer",
  },
];

export const cvCertificates: ProjectBulletProps[] = [
  {
    startText: "Economics BA, ",
    endText: "University Of Leicester",
  },
  {
    startText: "Computer Science with Artificial Intelligence MsC,",
    endText: "University Of York",
  },
  {
    startText: "Software Engineering,",
    endText: "BlackCodHer Bootcamp sponsored by GCHQ",
  },
  {
    startText: "Software Nanodegree,",
    endText: "Code First Girls sponsored by Goldman Sachs",
  },
  {
    startText: "CSW67 UN Women UK Delegate,",
    endText: "United Nations",
  },
  {
    startText: "CSW68 UN Women UK Delegate,",
    endText: "United Nations",
  },
  {
    startText: "Data Science,",
    endText: "Black Valley mentorship programme sponsored by Flywire",
  },
];

export const skills: ProjectBulletProps[] = [
  {
    startText: "Languages:",
    endText:
      "Typescript, Javascript (including Node.js), React, CSS, HTML, SQL. Familiar with Python and Java.",
  },
  {
    startText: "Frameworks:",
    endText: "React, Flask, Next.js",
  },
  {
    startText: "Databases:",
    endText: " MongoDB, PostgreSQL, MySQL",
  },
  {
    startText: "Containerization:",
    endText: "Docker, Kubernetes",
  },
  {
    startText: "Infrastructure:",
    endText: "AWS, Terraform",
  },
  {
    startText: "Tools:",
    endText: "Jira, Github, Figma, Trello, Gitlab, LaunchDarkly",
  },
  {
    startText: "Testing and Quality Assurance:",
    endText: "Manual QA, Cypress, Jest",
  },
  {
    startText: "Other Skills:",
    endText:
      "Design systems, Agile methodologies, Problem-solving, UX/UI design, Continuous integration and deployment (CI/CD), Diplomacy, Advocacy, User research, Public speaking, Teaching, Curriculum development, Coding bootcamp methodologies, Classroom management, Project management, Collaboration, Responsive design, Client-side scripting, Server-side scripting, Time management, Stakeholder management, Remote collaboration, Project estimation, Quality assurance, Educational technology integration.",
  },
  {
    startText: "Observability:",
    endText: "Grafana, Posthog, Observe",
  },
];

export const links = [
  {
    icon: website,
    alt: "Website icon",
    text: "reneelouise.tech",
    url: "https://www.reneelouise.tech",
  },
  {
    icon: githubDark,
    alt: "Github icon",
    text: "github.com/reneelouise",
    url: "https://www.github.com/reneelouise",
  },
];

export const handleDownload = (str: string) => {
  // Replace 'your-pdf-file.pdf' with the actual file name or URL
  const docUrl = process.env.PUBLIC_URL + str;

  // Create a temporary anchor element
  const link = document.createElement("a");

  // Set the href attribute to the PDF file URL
  link.href = docUrl;

  // Set the download attribute with the desired file name

  str.endsWith(".pdf")
    ? (link.download = "renee-louise-cv-2023.pdf")
    : (link.download = "renee-louise-cv-2023.docx");

  // Append the anchor element to the document body
  document.body.appendChild(link);

  // Trigger a click on the anchor element to start the download
  link.click();

  // Remove the anchor element from the document
  document.body.removeChild(link);

  // track CV download and indicate which type
  trackEvent(`downloaded CV: ${str}`);
};

// track events function
export const trackEvent = (event: string) => {
  posthog.capture(event);
};

export const videos: Video[] = [
  {
    id: "2Zzsoy9mnTQ?si=fnULjZtYAViozSBZ",
    title:
      "Set Up a Forever FREE Business Email with Zoho Mail | Step-by-Step Guide 2025! 🔥",
    description:
      "In this tutorial, we’ll show you how to set up a professional business email using Zoho Mail’s Forever Free plan. With Zoho, you can create a personalised email address for your business using your own domain name, helping to establish credibility and professionalism. Not only is this service free to use, but it’s also easy to set up and manage.",
    publishedAt: "Feb 2, 2025",
    thumbnailUrl: thumbnail9,
    duration: "04:19",
  },
  {
    id: "0fbycbx2VE0?si=AmwEHoUGkIj7nS3l",
    title:
      "The Come Up Diaries Ep 2: January Grind Results, Tech Career Services Launch & Future-Proofing!",
    description:
      "In this episode of The Come Up Diaries, I’m breaking down my biggest wins and lessons from January—balancing content creation, career growth, and building Devloop.sh & Blaq Byte while staying locked in on my goals.",
    publishedAt: "Jan 31, 2025",
    thumbnailUrl: thumbnail8,
    duration: "24:57",
  },
  {
    id: "YubDxwRZiOw?si=hyJCTSqnBjHGSHZL",
    title:
      "The Rise and Fall of DEI: What’s Happening in 2025? | DEI Decline Explained | Robby Starbuck | Trump",
    description:
      "Are we witnessing the collapse of Diversity, Equity, and Inclusion (DEI) initiatives? In this thought-provoking video, I break down the rise and fall of DEI, exploring why these programs were introduced, how they transformed industries, and what’s causing their rapid decline today.",
    publishedAt: "Jan 26, 2025",
    thumbnailUrl: thumbnail7,
    duration: "09:29",
  },
  {
    id: "Du2tLF4SqhE?si=nb4XgDRJbGWSggt-",
    title:
      "How I got into Into Tech with NO EXPERIENCE and NO CS DEGREE | Step by Step Guide to break into tech",
    description:
      "Think breaking into tech without a Computer Science degree is impossible? Think again! 🚀 In this video, I’m sharing a step by step guide to help you on your journey. The the strategies I used, and actionable steps YOU can take to start your dream career in tech—no degree required!",
    publishedAt: "Jan 20, 2025",
    thumbnailUrl: thumbnail4,
    duration: "10:30",
  },
  {
    id: "ho_kOe2iJHQ?si=GhypS79H5fBrNQlD",
    title:
      "10 Game-Changing Tips for Job Searching in a Tough Tech Job Market | Proven Tips to Land a Tech Job",
    description:
      "Struggling to break into tech or level up in today’s tough job market? You’re not alone—but you’re in the right place! In this video, I’m sharing 10 actionable tips to help you stand out and secure that dream tech role. From mastering in-demand skills to building a strong personal brand, these strategies are designed to help you win in any market.",
    publishedAt: "Jan 12, 2025",
    thumbnailUrl: thumbnail2,
    duration: "09:54",
  },
  {
    id: "mm2q6xErzFg?si=65sHpglykM4_Gx3T",
    title:
      "NEW M4 MacBook Pro! | 14inch, Space Black | Unboxing *ASMR* and first impressions 2025!",
    description:
      "Join me as I unbox the latest M4 MacBook Pro in the stunning Space Black finish! In this video, I’ll walk you through the unboxing experience, share my first impressions, and highlight the new features that make this 2025 release so exciting. Is it the best MacBook Pro yet? Let’s find out together!",
    publishedAt: "Jan 5, 2025",
    thumbnailUrl: thumbnail5,
    duration: "06:58",
  },
  {
    id: "JG64Y4kJZQQ?si=6XGoewf-lGaqyZjB",
    title:
      " The Come Up Diaries Ep 1: 2024 Reflections, Personal Branding & Launching My First Tech Product",
    description:
      "In this first episode, I’m reflecting on December 2024 and breaking down what went right, what didn’t, and the lessons I’ve learned in my main focus areas this month",
    publishedAt: "31 Dec 2024",
    thumbnailUrl: thumbnail3,
    duration: "29:24",
  },
  {
    id: "CA9ImRxfpJI?si=143LkgBS-hEHqchy",
    title:
      "16 Tips for Success In Your Career and Life In 2025 - How To Smash Your Goals",
    description:
      "This video comes just before the New Year, bringing you tips for success in 2025. These are some practical hands on tips",
    publishedAt: "29 Dec 2024",
    thumbnailUrl: thumbnail1,
    duration: "24:22",
  },
  {
    id: "_-9HApHvF14?si=YK6r_ajT16nZsGV6",
    title: "I'm back....as a Software Engineer - Introducing ReneeLouise.tech",
    description: "Introducing my new tech channel to the world",
    publishedAt: "Dec 22, 2024",
    thumbnailUrl: thumbnail6,
    duration: "28:08",
  },
];

export const tiktoks = [
  {
    videoId: "7462094849416695072",
    username: "reneelouise.tech",
    description:
      "API’s in 90 seconds ⚡️ Why are you not following me on ig btw? We’re breaking down complex technical concepts weekly!",
    tags: ["fyp", "tech", "learning", "api", "techtopics"],
  },
  {
    videoId: "7462274724526083360",
    username: "reneelouise.tech",
    description: "Step by step guide to break into tech in 2025 ⚡️🚀",
    tags: [
      "fyp",
      "tech",
      "getintotech",
      "breakintotech",
      "programming",
      "engineering",
    ],
  },
  {
    videoId: "7463205470128393505",
    username: "reneelouise.tech",
    description: "Tech topic Thursday. Caching in 90s ⚡️",
    tags: [
      "fyp",
      "explore",
      "tech",
      "techtopics",
      "caching",
      "redis",
      "cdn",
      "nemcached",
    ],
  },
];
